import React from "react";

function Preloader() {
  return (
    <div className="preloader-container">
      <div className="circle-preloader"></div>
    </div>
  );
}

export default Preloader;
